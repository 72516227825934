<template>
  <a-table
    :columns="columns"
    :data-source="features"
    bordered
  >
    <template #title>
      <a-typography-title :level="5">
        Features: &nbsp;
        <a-tag color="blue">{{ features.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ record, column }">
      <span v-if="column.dataIndex === 'in_use_anywhere'">{{ record.in_use_anywhere }}</span>
      <span v-if="column.dataIndex === 'suppressed'">{{ record.suppressed }}</span>
      <span v-if="column.dataIndex === 'actions'">
        <div class="actions">
          <a-popconfirm
            title="Are you sure? you want to suppress the feature?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteFeature({feature_id: record.id})"
          >
            <a-button type="primary" danger>Suppress</a-button>
          </a-popconfirm>
        </div>
      </span>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      columns: [
        {
          title: 'Feature',
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: 'In Use Anywhere',
          dataIndex: 'in_use_anywhere',
          align: 'center',
        },
        {
          title: 'Suppressed',
          dataIndex: 'suppressed',
          align: 'center',
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          align: 'center',
        }
      ],
    };
  },
  computed: {
    ...mapGetters(['features'])
  },
  methods: {
    ...mapActions(['deleteFeature'])
  },
};
</script>

<style scoped></style>
