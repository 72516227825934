<template>
  <a-table
    :columns="columns"
    :data-source="featureSubscriptions"
    bordered
  >
    <template #title>
      <a-typography-title :level="5">
        Subscribed Features: &nbsp;
        <a-tag color="blue">{{ featureSubscriptions.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ record, column, index }">
      <span v-if="column.dataIndex === 'feature_name'">{{ record.Feature.name }}</span>
      <span v-if="column.dataIndex === 'feature_suppressed'">{{ record.Feature.suppressed }}</span>
      <span v-if="column.dataIndex === 'organization_name'">{{ record.Organization.Org_name }}</span>
      <span v-if="column.dataIndex === 'actions'">
        <div class="actions">
          <a-popconfirm
            title="Are you sure? you want to delete the subscription?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteFeatureSubscription({subscribed_feature_id: record.id})"
          >
            <a-button type="primary" danger>Delete</a-button>
          </a-popconfirm>
        </div>
      </span>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      columns: [
        {
          title: 'Feature',
          dataIndex: 'feature_name',
          align: 'center',
        },
        {
          title: 'Suppressed',
          dataIndex: 'feature_suppressed',
          align: 'center',
        },
        {
          title: 'Organization',
          dataIndex: 'organization_name',
          align: 'center',
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          align: 'center',
        }
      ],
    };
  },
  computed: {
    ...mapGetters(['featureSubscriptions'])
  },
  methods: {
    ...mapActions(['deleteFeatureSubscription'])
  },
};
</script>

<style scoped></style>
